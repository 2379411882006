import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
Vue.config.ignoredElements = [/^ion-/]
Vue.config.productionTip = false;
window.axios = require('axios');
window.axios.interceptors.request.use(config => {
  const app_env = process.env.NODE_ENV;
  if (app_env == 'development'){
    config.params = {XDEBUG_SESSION_START: 'PHPSTORM'}
  }
  return config;
});

window.axios.defaults.baseURL = 'https://my.electricalcertificateapp.co.uk/api/v1/';
Vue.use(VModal)

Vue.use(Toasted, {
  fullWidth: true,
  duration: 3000,
  position: 'top-center'
});

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app');
