<template>
    <header class="header" role="banner">
      <div class="centering">
        <nav class="navigation">
          <ul class="left">
            <li>
              <router-link title="Home" :to="{path: '/'}"><img alt="Electrical Certificate App Logo" src="../assets/img/logo.png"></router-link>
            </li>
          </ul>
          <ul class="right">
            <li><a title="Features" rel="" href="#features">Features</a></li>
            <li><a title="Pricing" rel="" href="#pricing">Pricing</a></li>
          </ul>
        </nav>
      </div>
    </header>
<!--  <header class="header">-->
<!--    <router-link class="logo" title="Home" :to="{path: '/'}"><img alt="Gas Certificate App Logo" src="../assets/img/logo.png"></router-link>-->
<!--    <input class="menu-btn" type="checkbox" id="menu-btn"/>-->
<!--    <label class="menu-icon" for="menu-btn"><span class="navicon"></span></label>-->
<!--    <ul class="menu">-->
<!--      <li><a title="Launch Event" rel="" href="/event/tradeapps-launch">Launch Event</a></li>-->
<!--      <li><a title="Features" rel="" href="#features">Features</a></li>-->
<!--      <li><a title="Pricing" rel="" href="#pricing">Pricing</a></li>-->
<!--    </ul>-->
<!--  </header>-->
</template>

<script>
export default {
  name: "MenuBar",
}
</script>

<style scoped>

</style>
