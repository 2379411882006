<template>
    <div class="sale-banner with-bg">
      <a href="#sale" class="pointer h4">🎉 View our January Sale. 🎉</a>
    </div>
</template>

<script>
    export default {
        name: "Alert",

      data(){
          return {
            sale: '',
          }
      },

      mounted() {
          this.systemSettings()
      },

      methods: {
        systemSettings() {
          axios.get('/system-settings/sale').then(response => {
            this.sale = response.data;
          })
        },
      }
    }
</script>

<style scoped>

</style>
