<template>
  <div>
    <div class="main-hero">
      <div class="centering">
        <div class="hero-title">
          <h1 class="h2">Electrical Certificate App</h1>
          <h2 class="h1">Making your life simpler</h2>
          <p class="trial-text">Start your free 14 day trial now.</p>

          <div class="action-buttons">
            <a target="_blank" rel="noopener noreferrer" title="Download on the App Store"
               href="https://apps.apple.com/us/app/electrical-certificate-app/id1531731405?ls=1">
              <img src="../assets/img/appstore-badge.png" alt="Apple App Store">
            </a>
            <a target="_blank" rel="noopener noreferrer" title="Download on the Google Play Store"
               href="https://play.google.com/store/apps/details?id=uk.co.electricalcertificateapp.eca">
              <img src="../assets/img/google-play-badge.png" alt="Google Play">
            </a>
          </div>

          <div class="readmore">
            <a class="button align-items-center" title="Lean More" href="#start">Learn More
              <ion-icon name="arrow-down"></ion-icon>
            </a>
          </div>
        </div>
        <img class="hero-mockup" src="../assets/img/devices-mockup.png" alt="Electrical Certificate App Mockup">
      </div>
    </div>

    <section class="section showcase" id="features">
      <div class="centering">
        <div class="showcase-container">
          <div class="grid" data-aos="fade-up">
            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <div class="showcase-meta">
                <h2 class="showcase-title">The #1 All-In-One Tool for <span class="brand-text-color">Electrical Engineers</span></h2>

                <p>Join over 5000+ engineers saving hours per week with Electrical Certificate App.
                  Electrical Certificate App is a revolutionary new product that saves you hours per week by providing you with an all-in-one product from creating Electrical Certificates, managing your engineer's
                  calendars, to creating invoices and quotes.</p>
              </div>
            </div>

            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <img class="showcase-img" src="../assets/img/showcase-img/dashboard.jpg" alt="The #1 All-In-One Tool for Electrical Engineers">
            </div>
          </div>
        </div>

        <div class="showcase-container">
          <div class="grid reverse" data-aos="fade-up">
            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <div class="showcase-meta">
                <h2 class="showcase-title">A <span class="brand-text-color">Full Range</span> of <span class="brand-text-color">Certificates</span> for Any Job Type </h2>
                <p>Electrical Certificate App have a wide range of professional certificates from:</p>
                <ul>
                  <li>Minor Works</li>
                  <li>Three Circuit Minor Works</li>
                  <li>Electrical Installation Certificate</li>
                  <li>Domestic Electrical Installation Certificate</li>
                  <li>Electrical Installation Condition Certificate</li>
                  <li>Electrical Danger Notice</li>
                  <li>Visual Inspection Certificate</li>
                  <li>Earthing & Bonding Certificate</li>
                  <li>Electrical Isolation Certificate</li>
                  <li>Job Sheets</li>
                  <li>Fire Alarm Certificates</li>
                  <li>PAT Certificate</li>
                </ul>
              </div>
            </div>

            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <img class="showcase-img" src="../assets/img/showcase-img/certificates.jpg" alt="Certificates">
            </div>
          </div>
        </div>

        <div class="showcase-container">
          <div class="grid" data-aos="fade-up">
            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <div class="showcase-meta">
                <h2 class="showcase-title">Calendar / Diary <span class="brand-text-color">Management</span></h2>
                <p>Assign jobs to engineers, see a birds-eye view of all of their appointments, create a job sheet from a calendar event and more.</p>
              </div>
            </div>

            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <img class="showcase-img" src="../assets/img/showcase-img/calendar.jpg" alt="Calendar / Diary Management">
            </div>
          </div>
        </div>

        <div class="showcase-container">

          <div class="grid reverse" data-aos="fade-up">
            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <div class="showcase-meta">
                <h2 class="showcase-title">Built-In Industry <span class="brand-text-color">Invoice</span>/<span class="brand-text-color">Estimate</span> System</h2>
                <p>Create professional invoices and quotes and track your un-invoiced and outstanding invoices.</p>
              </div>
            </div>

            <div class="grid-xs-12 grid-s-6 grid-md-6">
              <img class="showcase-img" src="../assets/img/showcase-img/invoice.jpg" alt="Invoice and Quoting System">
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="section features-container">
      <div class="centering">
        <h2 class="h2 text-center">Even More Features!</h2>
        <div class="grid">
          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="checkmark-circle-outline"></ion-icon>
              </div>

              <h3>Full catalogue of certificates</h3>
              <p class="desc">Full range of Certificates including Job Sheets</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="calendar-outline"></ion-icon>
              </div>
              <h3>Calendar / Diary</h3>
              <p class="desc">Assign jobs to users, see a birds-eye view of all of your appointments and more.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="lock-closed-outline"></ion-icon>
              </div>
              <h3>Extremely Secure</h3>
              <p class="desc">A safe place to store all your customer and job address information,
                protected with bank level encryption.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="create-outline"></ion-icon>
              </div>
              <h3>Electronic Signatures</h3>
              <p class="desc">Reminders are automatically sent out on set due dates for your
                customers.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="save-outline"></ion-icon>
              </div>
              <h3>Keeping Details</h3>
              <p class="desc">All customer, job addresses, certificates and more are stored in a
                secure
                and searchable database. Making it super simple to keep all your records safe and
                secure.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="disc-outline"></ion-icon>
              </div>
              <h3>Customisation</h3>
              <p class="desc">Customisation
                Upload your own company logo as well as company details to the app.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="cloud-circle-outline"></ion-icon>
              </div>
              <h3>Unlimited Storage</h3>
              <p class="desc">Unlimited storage to keep your certificates and invoices stored for years.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="list-outline"></ion-icon>
              </div>
              <h3>To-Do List</h3>
              <p class="desc">Have a lot of things to remember? Write them down in a secure to-do list.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="document-outline"></ion-icon>
              </div>
              <h3>Create Estimates</h3>
              <p class="desc">Easily create estimates for jobs and convert them to an invoice with one
                tap.</p>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-6 grid-md-4">
            <div class="feature" data-aos="fade-up">
              <div class="services-icon">
                <ion-icon name="document-outline"></ion-icon>
              </div>
              <h3>Create Invoices</h3>
              <p class="desc">Easily create Invoices and send them directly to your client with one
                tap.</p>
            </div>
          </div>

        </div>
      </div>
    </section>

    <section class="section pricing" id="pricing">
      <div class="centering">
        <h2 class="text-center">Our Competitive Prices</h2>
        <div class="grid">
          <div class="grid-xs-12 grid-s-4">
            <div class="pricing-table" data-aos="fade-up">
              <h3 class="table-title">Monthly</h3>
              <p class="price">£15.00+VAT per month</p>
              <ul class="features-list">
                <li>Per month basis subscription</li>
                <li>The full catalogue of certificates</li>
                <li>Quotes, Invoices and Job Sheets</li>
                <li>Access the software on any device (iOS, Android PC or Mac)</li>
                <li>Unlimited Access to Android iOS and Desktop Computers</li>
                <li>Unlimited Access to Certificates</li>
                <li>Create Unlimited Customers and Certificates</li>
                <li>Free Storage of Certificates</li>
                <li>Secure backups stored in different locations</li>
                <li>Bank level protection</li>
                <li>Shared calendar</li>
                <li>To-Do list</li>
                <li>Unlimited Storage</li>
                <li>So many more features</li>
                <li>We'll get back to you within 15 minutes on our support chat.</li>
              </ul>
            </div>
          </div>

          <div class="grid-xs-12 grid-s-4">
            <div class="pricing-table" data-aos="fade-up">
              <h3 class="table-title">Yearly</h3>
              <p class="price">£162.00+VAT per year <br>(11% saving)</p>
              <ul class="features-list">
                <li>Per Yearly basis subscription</li>
                <li>The full catalogue of certificates</li>
                <li>Quotes, Invoices and Job Sheets</li>
                <li>Access the software on any device (iOS, Android PC or Mac)</li>
                <li>Unlimited Access to Android iOS and Desktop Computers</li>
                <li>Unlimited Access to Certificates</li>
                <li>Create Unlimited Customers and Certificates</li>
                <li>Free Storage of Certificates</li>
                <li>Secure backups stored in different locations</li>
                <li>Bank level protection</li>
                <li>Shared Calendar</li>
                <li>To-Do list</li>
                <li>Unlimited Storage</li>
                <li>So many more features</li>
                <li>We'll get back to you within 15 minutes on our support chat.</li>
              </ul>

            </div>
          </div>

          <div class="grid-xs-12 grid-s-4">
            <div class="pricing-table" data-aos="fade-up">
              <h3 class="table-title">User Add-on</h3>
              <p class="price">£146.06+VAT per year<br>(23% saving)</p>
              <p class="price">£12.17+VAT per month</p>
              <ul class="features-list">
                <li>Track engineers workload</li>
                <li>The full catalogue of certificates</li>
                <li>Quotes, Invoices and Job Sheets</li>
                <li>Access the software on any device (iOS, Android PC or Mac)</li>
                <li>Unlimited Access to Android iOS and Desktop Computers</li>
                <li>Unlimited Access to Certificates</li>
                <li>Create Unlimited Customers and Certificates</li>
                <li>Free Storage of Certificates</li>
                <li>Secure backups stored in different locations</li>
                <li>Bank level protection</li>
                <li>Shared Calendar</li>
                <li>To-Do list</li>
                <li>Unlimited Storage</li>
                <li>So many more features</li>
                <li>We'll get back to you within 15 minutes on our support chat.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section callout">
      <div class="centering">
        <h2 class="text-center text-white">Start your free 14 day trial now</h2>
        <div class="action-buttons">
          <a target="_blank" rel="noopener noreferrer" title="Download on the App Store"
             href="https://apps.apple.com/us/app/electrical-certificate-app/id1531731405?ls=1">
            <img src="../assets/img/appstore-badge.png" alt="Apple App Store">
          </a>
          <a target="_blank" rel="noopener noreferrer" title="Download on the Google Play Store"
             href="https://play.google.com/store/apps/details?id=uk.co.electricalcertificateapp.eca">
            <img src="../assets/img/google-play-badge.png" alt="Google Play">
          </a>
        </div>
      </div>
    </section>

    <section class="section contact">
      <div class="centering">
        <h2 class="h2 text-center">Contact us</h2>
        <div class="grid">
          <div class="grid-xs-12 grid-s-8">
            <form class="contact-form" v-on:submit.prevent="postContact">
              <div class="input-wrapper">
                <label>Full Name</label>
                <input type="text" placeholder="Full Name" v-model="name">
                <span class="has-error" v-if="errors.name">{{ errors.name[0] }}</span>
              </div>

              <div class="input-wrapper">
                <label>Email Address</label>
                <input type="email" placeholder="Email Address" v-model="email">
                <span class="has-error" v-if="errors.email">{{ errors.email[0] }}</span>
              </div>

              <div class="input-wrapper">
                <label>Subject</label>
                <input type="text" placeholder="Subject" v-model="subject">
                <span class="has-error" v-if="errors.subject">{{ errors.name[0] }}</span>
              </div>

              <div class="input-wrapper">
                <label>Message</label>
                <textarea placeholder="Your Message" v-model="message"></textarea>
                <span class="has-error" v-if="errors.message">{{ errors.name[0] }}</span>
              </div>
              <vue-recaptcha theme="dark" @verify="recaptchaVerified"
                             sitekey="6LfJMNoaAAAAAFKeMzZOEO_C_Z_5M41uGstC2V62"
                             :loadRecaptchaScript="true"></vue-recaptcha>
              <span class="has-error" v-if="errors.recaptcha">{{ errors.recaptcha[0] }}</span>

              <div class="input-wrapper">
                <button type="submit" class="button">Send</button>
              </div>
            </form>
          </div>

          <div class="grid-xs-12 grid-s-4">
            <div class="contact-meta">
              <div class="meta">
                <h3 class="h3">Phone</h3>
                <a title="0203 633 1775" href="tel:02036331775" class="h4">0203 633 1775</a>
              </div>

              <div class="meta">
                <h3 class="h3">Sales</h3>
                <a href="mailto:sales@electricalcertificateapp.co.uk" title="sales@electricalcertificateapp.co.uk" class="h4">sales@electricalcertificateapp.co.uk</a> <br>
                <a title="0203 633 1775" href="tel:02036331775" class="h4">0203 633 1775</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <modal @closed="closeBlackFridayPopup" name="blackfriday"
           :width="700"
           height="auto"
           :adaptive="true"
           :scrollable="true"
    >
      <div class="blackfriday-sale">
        <div class="close-button" @click="closeBlackFridayPopup"><ion-icon name="close-outline"></ion-icon></div>
        <img class="header-img" src="../assets/img/black_friday.png">

        <h2 class="h3 text-center">Start saving 12 hours per week</h2>
        <h3 class="h4 text-center pt-4">Download Now from your App Store</h3>

        <div class="action-buttons pt-4">
          <a target="_blank" rel="noopener noreferrer" title="Download on the App Store"
             href="https://apps.apple.com/us/app/electrical-certificate-app/id1531731405?ls=1">
            <img src="../assets/img/appstore-badge.png" alt="Apple App Store">
          </a>
          <a target="_blank" rel="noopener noreferrer" title="Download on the Google Play Store"
             href="https://play.google.com/store/apps/details?id=uk.co.electricalcertificateapp.eca">
            <img src="../assets/img/google-play-badge.png" alt="Google Play">
          </a>
        </div>

      </div>
    </modal>

  </div>

</template>

<script>
import AOS from 'aos'
import 'aos/dist/aos.css'
import VueRecaptcha from 'vue-recaptcha';
import AnimatedNumber from "animated-number-vue";
import Alert from "./Alert";
export default {
  name: 'Home',
  components: {Alert, VueRecaptcha, AnimatedNumber},

  data() {
    return {
      name: '',
      email: '',
      subject: '',
      message: '',
      recaptcha: '',
      signupForm: {
        name: '',
        email: ''
      },
      errors: [],
      isSaleEnabled: false,
      isBlackFridayAvailable: false,
    }

  },
  created() {
    AOS.init();
  },

  methods: {
    postContact() {
      axios.post('/landing/contact', this.$data).then(response => {
        this.$toasted.show("Message has been sent");
      }).catch(error => {
        this.errors = error.response.data;
      });
    },

    recaptchaVerified(response) {
      this.recaptcha = response;
    },

    signup() {
      window.location = `https://my.electricalcertificateapp.co.uk/register?name=${this.signupForm.name}&email=${this.signupForm.email}`
    },
  }
}
</script>
