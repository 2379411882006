<template>
  <div id="app">
    <menu-bar></menu-bar>
    <router-view/>
    <footer-component></footer-component>
  </div>
</template>

<style lang="scss">
@import "./assets/sass/layout";
</style>
<script>
import MenuBar from "./components/MenuBar";
import Alert from "@/components/Alert";
import FooterComponent from "@/components/FooterComponent";

export default {
  components: {FooterComponent, Alert, MenuBar},

}
</script>
