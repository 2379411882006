import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../components/Home";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
  window.Intercom("update");
});


export default router
