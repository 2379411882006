<template>
  <section class="section footer">
    <p>&copy; 2022 Trade Apps Ltd |
      <a target="_blank" href="https://support.gascertificateapp.co.uk/category/legal/" title="Policies">Policies</a> |
      <a target="_blank" href="https://support.gascertificateapp.co.uk/" title="Policies">Support</a> |
    </p>
    <div class="socials">
      <a rel="noopener noreferrer" target="_blank" title="Facebook" href="https://www.facebook.com/Electricalcertificateapp/">
        <ion-icon name="logo-facebook"></ion-icon>
      </a>
      <a rel="noopener noreferrer" target="_blank" title="Twitter" href="https://twitter.com/electrical_app">
        <ion-icon name="logo-twitter"></ion-icon>
      </a>
      <a rel="noopener noreferrer" target="_blank" title="LinkedIn" href="https://www.linkedin.com/company/16953352">
        <ion-icon name="logo-linkedin"></ion-icon>
      </a>
      <a rel="noopener noreferrer" target="_blank" title="Instagram" href="https://www.instagram.com/electricalcertificateapp/">
        <ion-icon name="logo-instagram"></ion-icon>
      </a>
    </div>
    <div>
      <img class="logo-small" alt="Trade Apps" src="./../assets/img/tradeapps-logo.png">
    </div>
  </section>
</template>

<script>
export default {
  name: "FooterComponent",
}
</script>

<style scoped>

</style>
